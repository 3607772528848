import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { Section, Box } from "../../components/Core";
import PostCard from "../../components/PostCard";
//import Pagination, { PageItem } from "../../components/Pagination";

import imgB1 from "../../assets/nightshift/blog-password-security.jpeg";
import imgB2 from "../../assets/nightshift/blog-go-kubernetes.jpeg";
import imgB3 from "../../assets/nightshift/blog-kubernetes-audit-events.jpeg";
import imgB4 from "../../assets/nightshift/blog-password-security.jpeg";
import imgB5 from "../../assets/nightshift/blog-ebpf.jpeg";
import imgB6 from "../../assets/nightshift/blog-kubernetes-scheduler.jpeg";

const BlogList = () => (
  <>
    {/* <!-- Blog section --> */}
    <Section className="position-relative">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg="4" className="mb-5">
            <PostCard
              img={imgB1}
              preTitle="Wed, 07 Apr 2021"
              title="Security Corner: Password Security for Beginners"
              readMore
              linkTo="/blog-password-security/"
            >
              Password lists are perhaps the most widely used source of seed information to attempt to reverse engineer 
              passwords by both security professionals and malicious actors alike. 
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={imgB2}
              preTitle="Mon, 03 May 2021"
              title="Kubernetes: How To Write Admission Controllers in Golang"
              readMore
              linkTo="/blog-kubernetes-admissions-in-golang/"
            >
              Admission controllers in Kubernetes provide extensibility to the K8s API, allowing additional 
              logic to be performed after authentication and authorization, but before schema validation and persistence.
            </PostCard>
          </Col>
          <Col lg="4" className="mb-5">
            <PostCard
              img={imgB3}
              preTitle="Tue, 18 Apr 2021"
              title="Kubernetes: Receiving Kubernetes Audit Events"
              readMore
              linkTo="/blog-kubernetes-audit-events/"
            >
              The Kubernetes Audit Subsystem is the de-facto standard for answering the ‘who what where why and how’ 
              of things that are interacting with your cluster API.
            </PostCard>
          </Col>
        </Row>
        <Box className="d-flex justify-content-center" mt={4}>
          {/* <Pagination>
            <PageItem>
              <FaAngleLeft />
            </PageItem>
            <PageItem>1</PageItem>
            <PageItem>2</PageItem>
            <PageItem>3</PageItem>
            <PageItem>...</PageItem>
            <PageItem>9</PageItem>
            <PageItem>
              <FaAngleRight />
            </PageItem>
          </Pagination> */}
        </Box>
      </Container>
    </Section>
  </>
);

export default BlogList;
